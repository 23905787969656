(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [];


// symbols:
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.waveone = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#171924").s().p("ADRAQIgRgKQgJgGgGgCQgJgEgLAAQgMAAgJAEQgFACgKAGIgRAKQgMAGgOAAQgOAAgMgGQgGgDgLgHQgJgGgGgCQgJgEgLAAQgLAAgJAEQgFACgJAGIgSAKQgLAGgPAAQgOAAgMgGQgGgDgLgHQgJgGgGgCQgJgEgLAAQgMAAgJAEQgFACgJAGIgRAKQgMAGgPAAQgOAAgMgGQgGgDgLgHQgJgGgGgCQgJgEgLAAIAAgPQAOAAAMAGIARALQAJAFAGADQAIADAMAAQASAAARgLQALgIAHgDQALgGAPAAQAOAAAMAGIARALQAJAFAGADQAIADAMAAQASAAARgLQALgIAHgDQALgGAOAAQAOAAAMAGQAGACALAJIAPAIQAIADAMAAQASAAARgLQALgIAHgDQALgGAPAAQAOAAAMAGIARALQAIAFAGADQAJADAMAAQAMAAAJgDQAGgDAIgFQALgIAHgDQALgGAPAAIAAAAIAAAPIAAAAQgMAAgJAEQgGACgJAGIgRAKQgMAGgOAAQgOAAgMgGg");
	this.shape.setTransform(31.4,2.2);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.waveone, new cjs.Rectangle(0,0,62.9,4.3), null);


(lib.birdfly = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#171924").s().p("AAAAWIgFgJQgKgOgggFQgXgDgWADIgCgOIAmgBQAnADARARQASgRAngDQAUgCASADIgCAOQgWgDgXADQggAFgKAOIgGAJIAAABg");
	this.shape.setTransform(9.5,2.3);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#171924").s().p("AAAAXIgCgDIgEgGQgHgLgSgFIgQgEQgLgCgLAAQgMgBgLACIAAgBIgCgNIAagBIAMAAQAjADATAQIACADQASgSAngDQAQgCAQABIAGABIgBAHIgCAHQgRgCgTADIgIABQgcAFgMAMIgCADIgGAIIAAABg");
	this.shape_1.setTransform(9.5,2.2);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#171924").s().p("AAAAYIgCgDIgEgGQgHgLgSgGIgQgEQgLgDgLAAQgMgBgLACIAAgBIgCgNIAagBIAMAAIAAAAQAjAEATARIACABQASgRAngEQARgCAQABIAFACIgBAGIgCAHQgRgCgUAEIgIABQgcAFgLANIgDAEIgCAEIgDADIAAABg");
	this.shape_2.setTransform(9.5,2.1);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#171924").s().p("AAAAZIgCgDIgEgGQgIgMgRgGIgQgFQgLgDgLAAQgMgBgLABIAAAAIgCgOIAaAAIAMAAIAAAAQAiAEAUASIACABQATgRAmgFQARgCAQABIAFACIgBAGIgDAHQgRgCgTAFIgIABQgcAGgLANIgDAEIgCAEIgDADg");
	this.shape_3.setTransform(9.5,2.1);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#171924").s().p("AAAAaIgCgEIgEgFQgHgMgSgGQgHgDgIgCQgLgEgLgBQgNgBgLABIAAgBIgCgMIAagBIAMABIAAAAQAiADAVATIACABQASgSAngFQAQgBARAAIADACIAAAHIgDAFQgRAAgUAFIgHABQgcAHgLANIgDAFIgCADIgDADIAAABg");
	this.shape_4.setTransform(9.5,2);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#171924").s().p("AgDAXIgDgGQgIgLgRgHIgPgGQgLgEgLgBQgNgBgLABIAAAAIgCgOIAaAAIAMABIAAAAQAhAEAWATIACABQATgSAmgFQAQgCARAAIADADIAAAGIgEAFQgQAAgVAGIgHACQgcAHgLAOIgCAEIgCAEIgBAAIgCACg");
	this.shape_5.setTransform(9.5,1.9);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#171924").s().p("AgBAcIgCgEIgDgGQgJgMgQgGIgPgHQgLgFgLAAQgOgCgKACIAAgCIgCgNQAFAAAVAAIAMABIABAAQAgAEAWAVIADAAQASgRAmgHQAQgCASAAIACADIAAAGIgFAGQgPgBgVAHIgHADQgbAHgMAOIgDAFIgCAEIgCABIgBAAg");
	this.shape_6.setTransform(9.5,1.9);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#171924").s().p("AgBAdIgCgEIgEgGQgIgMgQgHIgOgHQgMgFgLgBQgOgCgKABIAAAAIgCgOIAbABIALAAIABAAQAfAFAXAVIADAAQASgRAngHQAPgDASAAIACAEIAAAGIgFAFQgPgBgWAIIgGADQgbAJgMAOIgDAFIgCAEIgCAAg");
	this.shape_7.setTransform(9.5,1.8);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#171924").s().p("AACAeIgDAAIgCgEIgEgGQgIgMgQgIIgOgIQgMgFgLgCQgOgCgKACIAAAAIgCgOIAbAAIALABIABAAQAfAFAXAWIADAAQATgSAmgGQAPgEATAAIABAEIAAAHIgGADQgPAAgVAJIgHADQgbAKgLAPIgDAEIgCAEIAAAAg");
	this.shape_8.setTransform(9.5,1.7);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#171924").s().p("AgBAeIAAABIAAgBIgCgDIgEgHQgJgMgPgIIgOgIQgMgGgLgCQgOgCgKACIAAAAIgCgOQAFgBAWABIALACIABAAQAeAEAZAYIACgBQATgRAmgIQAQgEASgBIABAGIAAAGIgGADQgPAAgWAKIgHADQgaALgLAPIgDAFIgCADg");
	this.shape_9.setTransform(9.5,1.7);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#171924").s().p("AABAhIgCgCIgDgEIgEgGQgIgMgPgJIgOgJQgLgGgMgCQgOgCgKABIAAAAIgCgOQAGgBAVACIALABIABAAQAdAFAaAZIACgCQAUgRAlgIQAQgEATgBIAAAFIAAAHIgHADQgPAAgVALIgHADQgaAMgMAPIgCAFIgCAEIgBAAg");
	this.shape_10.setTransform(9.5,1.6);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#171924").s().p("AABAiIgCgCIgDgFIgEgGQgIgMgPgJQgGgFgHgEQgMgHgMgCQgPgCgIABIgBAAIgBgOQAGgBAUACIALABIACAAQAcAFAaAaIADgCQATgSAlgIQAQgDATgCIAAAGIgBAGIgGACQgOACgXALIgGADQgbANgKAPIgDAFIgDAEg");
	this.shape_11.setTransform(9.4,1.6);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#171924").s().p("AABAjIgCgCIgDgFIgEgGQgJgMgOgKIgNgJQgMgIgLgCQgQgDgIACIgBAAIgBgOQAGgBAUACIALABIACAAQAbAFAbAbIADgCQAUgSAkgIQAQgEATgCIAAAGIgBAHIgHABQgOACgWAMIgHADQgaAOgKAQIgDAEIgDAEIAAABg");
	this.shape_12.setTransform(9.4,1.5);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#171924").s().p("AAAAlIgCgDIgCgFIgFgGQgIgNgOgKIgMgKQgNgIgLgCQgRgDgHACIgBgBIgCgNQAHgCAUADIALABIACAAQAbAGAbAbIADgDQATgRAlgJQARgEASgDIAAAHIgBAHIgHABQgOACgXANIgHAEQgaAOgKAQIgDAFIgCAEIgBAAg");
	this.shape_13.setTransform(9.5,1.5);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#171924").s().p("AAAAmIgFgJQgKgQgQgNQgUgSgRgEQgQgDgIACIgCgOQAIgCASAEIAMABQAaAFAeAdQAcgcBDgJIgCAOQgOgCgfATQgeARgMASIgGAJIAAABg");
	this.shape_14.setTransform(9.5,1.5);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#171924").s().p("AAAAlIgCgDIgCgFIgFgGQgIgMgOgLQgGgGgGgDQgNgJgLgCQgQgDgIABIgBAAIgCgOQAJgBASADIALABIACAAQAaAFAcAcIADgDQATgRAlgJQARgEASgDIAAAGIgBAIIgHAAQgOADgXANIgGAEQgbAOgKAQIgCAEIgDAFIgBAAg");
	this.shape_15.setTransform(9.5,1.5);

	this.shape_16 = new cjs.Shape();
	this.shape_16.graphics.f("#171924").s().p("AABAkIgDgDIgCgFIgFgGQgIgMgOgKIgNgJQgNgIgLgCQgPgDgIACIgBgBIgCgNQAJgCASADIALABIABAAQAbAFAcAbIADgCQATgSAlgIQARgFASgCIAAAGIgBAIIgHABQgNABgYANIgGADQgaAOgLAQIgCAEIgDAFIAAAAg");
	this.shape_16.setTransform(9.5,1.5);

	this.shape_17 = new cjs.Shape();
	this.shape_17.graphics.f("#171924").s().p("AABAiIgCgCIgDgFIgEgGQgJgLgOgKIgNgJQgMgHgMgCQgOgDgJACIgBAAIgBgOQAIgCASADIALABIACAAQAcAFAaAaIADgCQATgSAlgIQAQgEATgBIAAAFIgBAHIgGACQgOABgXAMIgGADQgbANgKAQIgDADIgDAFIAAABg");
	this.shape_17.setTransform(9.4,1.6);

	this.shape_18 = new cjs.Shape();
	this.shape_18.graphics.f("#171924").s().p("AABAhIgCgBIgDgFIgEgGQgIgMgPgJIgOgIQgLgHgMgCQgOgCgKABIAAAAIgCgOQAJgBARACIALABIACAAQAdAFAaAZIACgBQAUgSAlgIQAQgDATgCIAAAFIAAAIIgHABQgOACgXAKIgGAEQgaAMgMAPIgCAEIgCAEIgBABg");
	this.shape_18.setTransform(9.5,1.6);

	this.shape_19 = new cjs.Shape();
	this.shape_19.graphics.f("#171924").s().p("AABAgIgCgBIgCgFIgEgFQgJgMgPgJIgOgIQgMgGgLgCQgOgCgKACIAAgBIgCgNQAKgCAQACIALABIACAAQAeAFAZAYIACgBQAUgSAlgHQAQgEASgBIABAFIAAAHIgGADQgPAAgWAKIgHADQgaAMgLAPIgDADIgCAFg");
	this.shape_19.setTransform(9.5,1.6);

	this.shape_20 = new cjs.Shape();
	this.shape_20.graphics.f("#171924").s().p("AgBAeIAAABIAAgBIgCgEIgEgGQgJgMgPgIIgOgHQgMgGgLgCQgOgCgKACIAAgBIgCgNQAKgCAQACIALABIACAAQAeAFAZAXIACgBQATgSAmgGQAQgEASgBIABAEIAAAIIgGADQgPAAgWAJIgGADQgbALgLAPIgDADIgCAFg");
	this.shape_20.setTransform(9.5,1.7);

	this.shape_21 = new cjs.Shape();
	this.shape_21.graphics.f("#171924").s().p("AACAdIgDABIgCgFIgEgFQgIgMgQgIIgOgHQgMgFgLgCQgOgCgKACIAAgBIgCgNQAKgCAQACIALABIACAAQAeAEAZAXIACgBQASgRAngHQAQgDASgBIABAEIAAAIIgFADQgQAAgVAIIgGADQgcAKgLAOIgDAEIgCAEIAAABg");
	this.shape_21.setTransform(9.5,1.7);

	this.shape_22 = new cjs.Shape();
	this.shape_22.graphics.f("#171924").s().p("AgBAcIgCgEIgEgGQgIgLgQgIQgHgEgIgCQgLgFgLgBQgOgCgKACIAAgBIgCgNQALgCAPABIALABIACAAQAfAFAYAVIACAAQATgSAlgGQARgDARAAIACADIAAAIIgFAEQgQgBgUAIIgHACQgbAJgMAPIgCADIgDAFIgCAAIgBABg");
	this.shape_22.setTransform(9.5,1.8);

	this.shape_23 = new cjs.Shape();
	this.shape_23.graphics.f("#171924").s().p("AgDAXIgDgFQgJgMgQgHQgHgEgIgCQgLgEgLgBQgOgCgKACIAAgBIgCgNQALgCAPABIALABIACAAQAgAEAXAVIABAAQATgRAmgGQAQgDASAAIACADIAAAIIgEAEQgQgBgVAHIgHACQgcAIgLAOIgCAEIgCAEIgBAAIgCACg");
	this.shape_23.setTransform(9.5,1.9);

	this.shape_24 = new cjs.Shape();
	this.shape_24.graphics.f("#171924").s().p("AAAAbIgDgEIgDgGQgJgLgQgHIgPgFQgMgEgLgBQgNgCgKACIAAgBIgCgNQALgCAPABIALABIABAAQAiAEAVAUIACAAQATgRAmgFQAQgDASAAIACADIAAAHIgDAFQgRgBgUAGIgHACQgcAHgMAOIgCAEIgCAEIgDACIAAAAg");
	this.shape_24.setTransform(9.5,1.9);

	this.shape_25 = new cjs.Shape();
	this.shape_25.graphics.f("#171924").s().p("AAAAaIgCgFIgEgFQgIgLgRgHIgPgFQgMgDgLgBQgNgBgKACIAAgBIgCgNQAMgCAOABIALABIABAAQAiADAVATIACABQASgSAngEQAQgDARABIADACIAAAHIgDAGQgRgCgTAGIgIABQgcAHgLAOIgDADIgCAEIgDADIAAAAg");
	this.shape_25.setTransform(9.5,2);

	this.shape_26 = new cjs.Shape();
	this.shape_26.graphics.f("#171924").s().p("AAAAZIgCgEIgEgFQgIgMgSgGQgHgDgIgBQgLgDgLgBQgNgBgKACIAAgBIgCgNQALgCAOABIAMABIABAAQAiADAVASIABABQATgRAmgFQARgCAQABIAFACIgBAHIgDAGQgRgCgTAFIgIABQgcAGgLANIgDAEIgCAEIgDADg");
	this.shape_26.setTransform(9.5,2.1);

	this.shape_27 = new cjs.Shape();
	this.shape_27.graphics.f("#171924").s().p("AAAAYIgCgEIgEgFQgHgMgTgFQgHgDgIgBQgLgCgLgBQgNgBgKACIAAgBIgCgNQAMgCANABIALAAIACAAQAjAEAUARIABABQASgRAngEQARgCAQABIAFACIgBAHIgCAGQgSgCgTAEIgIABQgcAFgLANIgDADIgFAIIAAABg");
	this.shape_27.setTransform(9.5,2.1);

	this.shape_28 = new cjs.Shape();
	this.shape_28.graphics.f("#171924").s().p("AAAAXIgCgEIgEgFQgHgLgTgFIgPgEQgMgCgLAAQgLAAgLABIAAgBIgCgNQAMgBANAAIALAAIACAAQAkAEATAQIABACQASgSAngDQARgCAQABIAFABIgBAIIgCAGQgRgCgTADIgIABQgcAFgMAMIgCADIgGAIIAAABg");
	this.shape_28.setTransform(9.5,2.2);

	this.shape_29 = new cjs.Shape();
	this.shape_29.graphics.f("#171924").s().p("AAAAWIgFgJQgKgOgggFQgXgDgWADIgCgOQASgDAUACQAnADARARQASgRAngDQAUgCASADIgCAOQgWgDgXADQggAFgKAOIgGAJIAAABg");
	this.shape_29.setTransform(9.5,2.3);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_13}]},1).to({state:[{t:this.shape_14}]},1).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_21}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_23}]},1).to({state:[{t:this.shape_24}]},1).to({state:[{t:this.shape_25}]},1).to({state:[{t:this.shape_26}]},1).to({state:[{t:this.shape_27}]},1).to({state:[{t:this.shape_28}]},1).to({state:[{t:this.shape_29}]},1).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,19,4.6);


(lib.waveflow = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_6 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ak5CQIAAkfIJzAAIAAEfg");
	mask.setTransform(71.5,70.4);

	// 1
	this.instance = new lib.waveone();
	this.instance.parent = this;
	this.instance.setTransform(8.9,69.6,1,1,0,0,0,31.4,2.1);

	var maskedShapeInstanceList = [this.instance];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance).to({x:71.7},79,cjs.Ease.none).wait(1));

	// Layer_5 (mask)
	var mask_1 = new cjs.Shape();
	mask_1._off = true;
	mask_1.graphics.p("Ak5CQIAAkfIJzAAIAAEfg");
	mask_1.setTransform(71.5,70.4);

	// 2
	this.instance_1 = new lib.waveone();
	this.instance_1.parent = this;
	this.instance_1.setTransform(71.8,69.6,1,1,0,0,0,31.4,2.1);

	var maskedShapeInstanceList = [this.instance_1];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance_1).to({x:134.5},79,cjs.Ease.none).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(40.1,67.5,62.9,4.3);


(lib.bird = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.instance = new lib.birdfly();
	this.instance.parent = this;
	this.instance.setTransform(9.5,2.2,1,1,0,0,0,9.5,2.2);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({x:10.5,y:-0.8},14,cjs.Ease.sineInOut).to({x:9.5,y:2.2},15,cjs.Ease.sineInOut).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,19,4.6);


// stage content:
(lib.preload = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.instance = new lib.bird();
	this.instance.parent = this;
	this.instance.setTransform(56.4,24.2,1,1,0,0,0,9.5,1.4);

	this.instance_1 = new lib.waveflow();
	this.instance_1.parent = this;
	this.instance_1.setTransform(8.2,0.1,1,1,0,0,0,31.4,2.1);

	this.instance_2 = new lib.waveflow();
	this.instance_2.parent = this;
	this.instance_2.setTransform(8.2,-19.9,1,1,0,0,0,31.4,2.1);

	this.instance_3 = new lib.waveflow();
	this.instance_3.parent = this;
	this.instance_3.setTransform(8.2,-9.9,1,1,0,0,0,31.4,2.1);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(2.3,70.8,125.7,60.1);
// library properties:
lib.properties = {
	id: 'EF3EE9CC28D64E16A7DFDAB1C1C513A1',
	width: 96,
	height: 96,
	fps: 24,
	color: "#FFFFFF",
	opacity: 1.00,
	manifest: [],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }



an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['EF3EE9CC28D64E16A7DFDAB1C1C513A1'] = {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
    return an.compositions[id];
}

})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;

